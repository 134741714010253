import React from "react"
import TranslationService from "../components/ServiceSection/TranslationService"

import Layout from "../components/layout"

const Translation = () => (
  <Layout>
    <TranslationService />
  </Layout>
)

export default Translation
