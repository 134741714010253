import React from 'react'
import {ServiceContainer, MainTitle, Content, Title, Text} from './ServiceItemElements'
import BackgroundImage from 'gatsby-background-image'
import {graphql, StaticQuery} from 'gatsby'
import QuoteFormModal from '../../components/Forms/QuoteFormModal'

import "../HeroSection/HeroSection.css"



const ServicePageImages = () => (
  <StaticQuery
  query={graphql`
    query {
      heroImage: file(relativePath: { eq: "AboutHero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `}
  render={data => {
    // Set ImageData.
    const imageData = data.heroImage.childImageSharp.fluid
    return (
      <BackgroundImage
        Tag="section"
        className="heroContainer"
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
         
         <div className="overlay">
           <div className="content-area">
           <h1 style={h1Style}>Translation Services</h1>
              <QuoteFormModal />
           </div>
          </div> 
      </BackgroundImage>
    )
  }}
/>
)

// const sectionStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
// };

const h1Style = {
  fontSize: '2.5rem'
}


// const pStyle = {
//   letterSpacing: '0.04rem',
//   textAlign: 'flex-start',
//   color: '#535452',
//   margin: '80px 50px',

// }




const TranslationService = () => {
    return (
   
        <ServiceContainer>
        <div>
            <div>
                <ServicePageImages />
            </div>
          <MainTitle>Translation Services</MainTitle>
          <Content>
                <div>
                <Title>Document Translation</Title>
                <Text>
                We have a well-trained and educated linguistic professionals. They are specialized in translating medical documents, Human resources, Financial documents, employee handouts, training materials, job descriptions, contracts, and legal documents.  

                Any translated document reviewed and proofread before it is finalized. Our prices are low, and we answer your questions within few hours. For free estimate please fill out the quote form.
                </Text>
                </div>
            </Content>
      </div>
      </ServiceContainer>
     
    )
}

export default TranslationService
